p { 
    margin:0;
    padding-top: 15px;
    padding-left: 6px;
  }
  .infobar{
    background-color: #e4e4e4 !important;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    height: 55px;
  
  }
  
  .hipleftcontainer {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    color: black;
    padding-left: 20px;
    font-size: large;
    padding-top: 0px;
  }
  .circlehipleft{
    background-color: cyan;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 25px;
    width: 25px;
  }
  
  .bladdercontainer {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    color: black;
    padding-left: 25px;
    font-size: large;
    padding-top: 0px;
  }
  .circlebladder{
    background-color: blue;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 25px;
    width: 25px;
  }
  
  .coloncontainer {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    color: black;
    padding-left: 25px;
    font-size: large;
    padding-top: 0px;
    
  }
  .circlecolon{
    background-color: yellow;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 25px;
    width: 25px;
  }
  
  .hiprightcontainer {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    color: black;
    padding-left: 25px;
    font-size: large;
    padding-top: 0px;

  }
  .circlehipright{
    background-color: green;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 25px;
    width: 25px;
  }