.App {
  text-align: center;

}

.App-logo {
  max-width: 330px;
  /* width: 380px; */
  pointer-events: none;
  padding-left: 20px;
}

.App-header {
  /* background-color: #282c34; */
  background-color: #34485c;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.App-link {
  color: #61dafb;
}
