.App {
  text-align: center;
}

.contrastSlider {
  margin: auto;
  text-align: center;
  align-items: center;
  width: 50%;
}
.dot {
  height: 25px;
  width: 25px;
  color: cyan;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: blue;

}

.contrastDropDown {
  width: 100%;
}

.loadingimg {
  width: 15%;
}

.sliderimg {
  width: 40%;
  text-align: center;
  align-items: center;
  margin: auto;
}

.imageDiv {
  width: 80%;
  text-align: center;
  align-items: center;
  margin: auto;
}